import { useEffect, useState } from "react";
import { HiMenu, HiChevronRight } from "react-icons/hi";
import Button from "../Button/index";
import LocationItem from "./LocationItem";
import AddLocation from "./AddLocation";
import { IoMdClose } from "react-icons/io";
import Guac from "../Icons/Guac";
import Typography from "../Typography";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useLocation } from "../../store/location.store";
import { useSeniority } from "../../store/seniority.state";
import { useModal } from "../../hooks/useModal";
import ResetPasswordModal from "../Modals/ResetPasswordModal";
import { useEstimationContext } from "../../hooks/context/useEstimationContext";
import { useGetLocationsQuery } from "../../store/locationApi";

interface SideBarProps {
  isCalendarLoading: boolean;
  type: "dashboard" | "estimation" | "shiftPoints";
  setParams: {
    dashboard: any | undefined;
    estimation: any | undefined;
    shiftPoints: undefined;
  };
}

const SideBar = ({ type, isCalendarLoading, setParams }: SideBarProps) => {
  const { isLoading, data: _locations } = useGetLocationsQuery({
    seniority: 4,
    all: true,
  });

  const [searchParams] = useSearchParams();
  const { activeId, setActiveId, setLocations, locations } = useLocation();
  const {
    setDisabled,
    seniorities,
    activeId: activeSeniorityId,
  } = useSeniority();

  const { resetStates } = useEstimationContext();

  useEffect(() => {
    if (searchParams.get("locationId") !== null) {
      setActiveId(searchParams.get("locationId")!);
    }
  }, []);

  useEffect(() => {
    if (_locations?.locations?.length) {
      setLocations(_locations.locations);
      const location = _locations.locations.find(
        (loc: any) => loc._id == activeId
      );
      if (location?.seniorities.includes(4)) {
        for (const seniority of seniorities) {
          setDisabled(seniority.id, false);
        }
        return;
      }
      for (const seniority of seniorities) {
        if (location?.seniorities.includes(seniority.id)) {
          setDisabled(seniority.id, false);
        } else {
          setDisabled(seniority.id, true);
        }
      }
    }
  }, [activeId, _locations]);

  const navigate = useNavigate();

  const [overflowY, setOverflowY] = useState<
    "visible" | "hidden" | "clip" | "scroll" | "auto"
  >("auto");

  const { openModal } = useModal();

  const sideBarListItems = [
    {
      id: 1,
      name: "Reset Password",
      onClick: () => {
        openModal(<ResetPasswordModal />);
      },
    },
    {
      id: 2,
      name: "Logout",
      onClick: () => {
        localStorage.removeItem("token");
        navigate("/");
      },
    },
  ];

  const [isExpanded, setIsExpanded] = useState(false);
  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="flex relative">
      <div className="h-full flex flex-col rounded-r-2xl bg-secondary py-4 w-28">
        <div className="w-full flex flex-col gap-6 justify-center items-center pb-10">
          <Link to="/dashboard">
            <Guac />
          </Link>
          {type !== "shiftPoints" && (
            <>
              <Button className="!bg-transparent flex items-center justify-center">
                <HiMenu
                  className="text-[#FFFEF8] h-10 w-10 cursor-pointer"
                  onClick={handleExpand}
                />
              </Button>
              <AddLocation />
            </>
          )}
        </div>

        <div
          className="sidebar h-full overflow-y-auto"
          style={{
            overflowY,
          }}
        >
          {isLoading
            ? [...Array(5).keys()].map((v, indx) => (
                <LocationItem
                  key={indx}
                  skeleton={true}
                  name=""
                  id={""}
                  isActive={false}
                />
              ))
            : locations?.map((loc: any) => (
                <LocationItem
                  onMenuOpen={(isOpen) => {
                    if (isOpen) {
                      setOverflowY("visible");
                    } else {
                      setOverflowY("auto");
                    }
                  }}
                  showContextMenu={type !== "shiftPoints"}
                  key={loc._id}
                  name={loc.shortLabel}
                  disabled={isCalendarLoading}
                  id={loc._id}
                  isActive={loc._id == activeId}
                  onSwitch={(activeId) => {
                    setActiveId(activeId);
                    setParams[type]?.({ locationId: loc._id });
                    if (type === "estimation") {
                      resetStates(activeSeniorityId);
                    }
                  }}
                />
              ))}
        </div>
      </div>
      {isExpanded ? (
        <div className="w-96 shadow-2xl h-full p-4 !bg-white border-r-2 border-r-primary shadow-inset z-10 absolute left-28 flex flex-col">
          <div className="flex items-center !justify-between p-4 border-b-2 border-b-primary w-full">
            <Typography
              tag="h2"
              className="mb-0 leading-normal font-semibold pl-2 text-xl"
            >
              GUAC
            </Typography>
            <IoMdClose
              className=" text-black cursor-pointer"
              size={25}
              onClick={handleExpand}
            />
          </div>
          <Link
            to="/dashboard/management"
            className="font-DM flex justify-between items-center border-b-2 border-b-primary font-bold text-[#323A46] mt-3 px-3 pb-3"
          >
            <span>Management</span>
            <HiChevronRight size={30} />
          </Link>
          <Link
            to="/dashboard/preferences"
            className="font-DM flex justify-between items-center border-b-2 border-b-primary font-bold text-[#323A46] my-3 px-3 pb-3"
          >
            <span>Preferences</span>
            <HiChevronRight size={30} />
          </Link>
          <div className="flex-grow mt-4 flex flex-col-reverse !bg-white rounded-lg w-full text-gray-900">
            {sideBarListItems.map((item) => {
              return (
                <div
                  className="p-3 py-1 w-full"
                  key={item.id}
                  onClick={item.onClick}
                >
                  <Typography
                    tag="p"
                    className="font-DM !font-bold text-[#64748B] cursor-pointer"
                  >
                    {item.name}
                  </Typography>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SideBar;
