import { useEffect, useState } from "react";
import { HiSearch } from "react-icons/hi";
import { useDebouncedCallback } from "use-debounce";
import { Dna } from "react-loader-spinner";
import { toast } from "react-toastify";

import Input from "../Input/index";
import DoctorCardVar from "../Cards/DoctorCardVar";
import Badge from "../Icons/Badge";
import Select from "../Input/Select";
import Typography from "../Typography/index";
import Button from "../Button/index";
import { useSeniority } from "../../store/seniority.state";
import api from "../../store/api";
import RoundedCalendar from "../Calender/RoundedCalander";
import { formatDateAPI } from "../../utils/formatTime";

const LeaveManagementTabItem = () => {
  const { activeId: activeSeniorityId } = useSeniority();

  const [reason, setReason] = useState<string>("");

  const [selectedDoctorId, setSelectedDoctorId] = useState<string>("");
  const [newLeaveDates, setNewLeaveDates] = useState<Date[]>([]);

  // const debouncedGetDoctors = useDebouncedCallback(
  //   (params) => getDoctors(params),
  //   1000
  // )

  const {
    fetchFn: getLeaveQuotaByDoctorId,
    data: leaveQuota,
    isLoading: isQuotaLoading,
  } = api.leaveQuota.useGetLeaveQuotaByDoctorId();

  const { fetchFn: setLeaves, isLoading: isSetLeavesLoading } =
    api.doctor.useSetLeaves();

  const [selectedLeaveQuotaId, setSelectedLeaveQuotaId] = useState<string>("");

  const [searchQuery, setSearchQuery] = useState<string>("");

  // useEffect(() => {
  //   debouncedGetDoctors({
  //     params: {
  //       seniority: activeSeniorityId,
  //       q: searchQuery,
  //     },
  //   })
  // }, [activeSeniorityId, searchQuery])

  return (
    <div className=" grid grid-cols-3 overflow-auto w-full max-w-[95%] mx-auto">
      <div className="col-span-1 pt-8">
        <Input
          type="text"
          icon={{
            content: <HiSearch size={20} />,
            position: "left",
          }}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          placeholder="Search Group or People..."
          className="!text-sm placeholder:text-sm !rounded-2xl !placeholder:text-sm"
        />
      </div>
      <div className="col-span-2">
        {isQuotaLoading ? (
          <div className="h-full w-full flex flex-col justify-center items-center">
            <Dna height={100} width={100} />
          </div>
        ) : null}
        {!isQuotaLoading && leaveQuota?.quotas?.length > 0 ? (
          <div className="max-w-lg mx-auto py-8">
            <Typography tag={"span"} className="font-semibold">
              Type Of Leave
            </Typography>
            <Select
              value={selectedLeaveQuotaId}
              className="mt-4"
              onChange={(e) => setSelectedLeaveQuotaId(e.target.value)}
              options={
                <>
                  <option value="">Select Leave Type</option>
                  {leaveQuota?.quotas?.map((opt: any, i: number) => (
                    <option key={i} value={opt._id}>
                      {opt.label} ({opt.leaveDays})
                    </option>
                  ))}
                </>
              }
            />
          </div>
        ) : null}
        {!isQuotaLoading && !leaveQuota?.quotas?.length ? (
          <Typography
            tag="p"
            className="text-center font-semibold mx-auto py-6"
          >
            No Leave quota available for this doctor.
          </Typography>
        ) : null}
      </div>
      <div className="col-span-1 overflow-auto  flex-grow ">
        <div className="  flex flex-col gap-4 overflow-auto px-3 py-4">
          {true ? (
            [].map((doctor: any, i: number) => (
              <DoctorCardVar
                onClick={() => {
                  setSelectedDoctorId(doctor._id);
                  getLeaveQuotaByDoctorId({
                    data: {
                      doctorId: doctor._id,
                    },
                  });
                }}
                selected={selectedDoctorId === doctor._id}
                key={i}
                variant="experience"
                name={doctor.user.name}
                icon={
                  <Badge
                    height={24}
                    width={24}
                    className="absolute top-0 right-0"
                    varient={"blue"}
                  />
                }
                image={"https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"}
                experienceLevel={doctor.experience}
                efficiencyScore={doctor.efficiencyScore}
              />
            ))
          ) : (
            <div className="h-full w-full flex flex-col justify-center items-center">
              <Dna height={100} width={100} />
            </div>
          )}
        </div>
        {/* </div> */}
      </div>
      {!isQuotaLoading && leaveQuota?.quotas?.length > 0 ? (
        <div className="col-span-2 overflow-auto  flex-grow pt-3">
          <RoundedCalendar
            initial={{
              selectedDates: []
            }}
            onChange={(dates) => {
              setNewLeaveDates(dates);
            }}
          />
          <div className="max-w-lg mx-auto">
            <textarea
              name=""
              id=""
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              className="w-full ring-2 ring-secondary bg-primary outline-none rounded-lg p-4 font-semibold "
              rows={3}
              placeholder="Leave description"
            ></textarea>
            <Button
              disabled={isSetLeavesLoading}
              className="mt-4 flex item-center justify-center"
              onClick={async () => {
                const data = {
                  doctorId: selectedDoctorId,
                  leaves: newLeaveDates.map((date) => ({
                    date: formatDateAPI(date),
                    reason,
                    quota: selectedLeaveQuotaId,
                  })),
                };

                const setLeavesRes = await setLeaves({
                  data: [data],
                  params: {
                    operation: "push",
                  },
                });

                const { isSuccess, isError, error } = setLeavesRes.unwrap();

                if (isSuccess) {
                  toast.success("Leave assigned successfully");
                  setSelectedDoctorId("");
                  setNewLeaveDates([]);
                  setReason("");
                  // getDoctors({
                  //   params: {
                  //     seniority: activeSeniorityId,
                  //     q: searchQuery,
                  //   },
                  // });
                }

                if (isError) {
                  toast.error(error?.response?.data?.message);
                }
              }}
            >
              {isSetLeavesLoading ? "Loading..." : "Assign Leave"}
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default LeaveManagementTabItem;
