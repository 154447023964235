import { useEffect, useMemo, useRef, useState } from "react";
import { POINTS } from "./ScoringBlock";
import { useRunOnChange } from "../../hooks/runOnChange";
import CarotUp from "../Icons/CarotUp";
import CarotDown from "../Icons/CarotDown";
import { AnimatePresence, motion } from "framer-motion";
import RoundedCancel from "../Icons/RoundedCancel";
import RoundedTick from "../Icons/RoundedTick";
import { TimeBlock } from "../Dashboard/DayView";

export type PointsData = {
  dayOfWeek: Record<string, number>;
  holidays: Record<string, number>;
  timeBlock: TimeBlock;
  _id: string;
};

const PointInputBar = ({
  onCancel,
  onUpdate,
  label,
  initialPoint,
}: {
  label: string;
  onCancel: () => void;
  onUpdate: (point: number) => Promise<void>;
  initialPoint: number;
}) => {
  const [inputPoint, setInputPoint] = useState(initialPoint);
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <motion.div
      className="flex gap-2 px-4 py-2"
      initial={{ opacity: 0, zIndex: 0 }}
      animate={{ opacity: 1, zIndex: 10 }}
      exit={{ opacity: 0, zIndex: 0 }}
      transition={{ duration: 0.08 }}
    >
      <div className="bg-white flex py-1 px-2 text-base font-medium rounded-md gap-2 flex-grow justify-between items-center">
        <div>{label}</div>
        <div className="bg-white border-black border-[1px] rounded-md h-fit">
          <input
            type="text"
            ref={inputRef}
            className="w-[30px] rounded-md text-center outline-none"
            value={inputPoint}
            onChange={(e) => {
              if (
                e.target.value.length <= 2 &&
                !Number.isNaN(Number(e.target.value))
              ) {
                setInputPoint(Number(e.target.value));
              }
            }}
          />
        </div>
      </div>
      <div className="flex bg-white rounded-lg justify-between items-center border-black border-[1px] py-1 px-1.5">
        <div
          className="flex items-center h-full px-2.5 cursor-pointer"
          onClick={onCancel}
        >
          <RoundedCancel active={true} size={{ height: "20", width: "20" }} />
        </div>
        <div
          className="flex items-center h-full border-l-[1px] border-gray3 px-2.5 cursor-pointer"
          onClick={async () => await onUpdate(inputPoint)}
        >
          <RoundedTick active={true} size={{ height: "20", width: "20" }} />
        </div>
      </div>
    </motion.div>
  );
};

const PointsBlock = ({
  isActive,
  isPointsShowBlock,
  setIsPointsShowBlock,
  pointsType,
  label,
  points,
  triggerReset,
  pointsData,
  updateShiftPoint,
}: {
  isActive: boolean;
  isPointsShowBlock: boolean;
  setIsPointsShowBlock: (isActive: boolean) => void;
  label: string;
  pointsType: "weekdays" | "weekends" | "holidays";
  points: POINTS;
  triggerReset: boolean;
  pointsData: PointsData;
  updateShiftPoint: (key: string, point: number) => Promise<void>;
}) => {
  const [showPointBlock, setShowPointBlock] = useState<
    { key: string; parentKey: string } | undefined
  >(undefined);

  useRunOnChange(triggerReset, () => {
    setShowPointBlock(undefined);
  });

  const shouldShowPoint = useMemo(
    () =>
      points.reduce<{
        prevVal: undefined | number;
        isEqual: boolean;
      }>(
        (obj, point) => {
          if (!obj.isEqual) {
            return { prevVal: undefined, isEqual: false };
          }
          if (!obj.prevVal) {
            return {
              prevVal:
                pointsData[point.parentKey as "dayOfWeek" | "holidays"][
                  point.key
                ],
              isEqual: obj.isEqual,
            };
          }
          return {
            prevVal:
              pointsData[point.parentKey as "dayOfWeek" | "holidays"][
                point.key
              ],
            isEqual:
              pointsData[point.parentKey as "dayOfWeek" | "holidays"][
                point.key
              ] === obj.prevVal,
          };
        },
        { prevVal: undefined, isEqual: true }
      ).isEqual,
    [points, pointsData]
  );

  return (
    <div className="bg-gray rounded-xl" key={pointsType}>
      <div
        className={`w-full rounded-xl ${
          isActive ? "h-[65px]" : "h-[55px]"
        } flex justify-between items-center bg-white p-2 cursor-pointer group`}
        onClick={() => {
          if (isActive) {
            setIsPointsShowBlock(!isPointsShowBlock);
            setShowPointBlock(undefined);
          }
        }}
      >
        <div className="font-medium text-base">{label}</div>
        {isPointsShowBlock && shouldShowPoint ? (
          <div className="mr-4">
            <CarotUp color="#727272" />
          </div>
        ) : (
          <>
            {shouldShowPoint && (
              <>
                <div className="mr-4 font-medium text-base group-hover:hidden">
                  {
                    pointsData[points[0].parentKey as "dayOfWeek" | "holidays"][
                      points[0].key
                    ]
                  }
                </div>
                <div className="mr-4 hidden group-hover:block">
                  <CarotDown color="#727272" />
                </div>
              </>
            )}
          </>
        )}
      </div>
      <AnimatePresence>
        {(isPointsShowBlock || !shouldShowPoint) && (
          <motion.div
            className="flex gap-3 px-4 py-2"
            initial={{ height: 0, zIndex: 0 }}
            animate={{ height: "auto", zIndex: 1 }}
            exit={{ height: 0, zIndex: 0 }}
            transition={{ duration: 0.08 }}
          >
            {points.map((p) => (
              <div
                key={p.key}
                className={`bg-white rounded-md text-center p-1 text-base font-medium flex-grow cursor-pointer transition-[border] ${
                  p.key === showPointBlock?.key
                    ? "border-secondary border-[1px]"
                    : ""
                }`}
                onClick={() => {
                  if (isActive) {
                    setShowPointBlock(
                      p.key === showPointBlock?.key
                        ? undefined
                        : { key: p.key, parentKey: p.parentKey }
                    );
                  }
                }}
              >
                <div className="bg-gray rounded-md group-hover:hidden mb-2 text-sm">
                  {pointsData[p.parentKey as "dayOfWeek" | "holidays"][p.key]}
                </div>
                {p.label}
              </div>
            ))}
          </motion.div>
        )}
        <div className="relative">
          {showPointBlock && (
            <PointInputBar
              key={showPointBlock.key}
              label={
                points.find((p) => p.key === showPointBlock.key)?.labelInput ??
                ""
              }
              onCancel={() => {
                setShowPointBlock(undefined);
              }}
              onUpdate={async (point: number) => {
                await updateShiftPoint(showPointBlock.key, point);
                setShowPointBlock(undefined);
              }}
              initialPoint={
                pointsData[
                  showPointBlock.parentKey as "dayOfWeek" | "holidays"
                ][showPointBlock.key]
              }
            />
          )}
        </div>
      </AnimatePresence>
    </div>
  );
};

export default PointsBlock;
