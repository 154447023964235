import { useCallback, useEffect } from "react";

import { useGetDoctorsQuery, useGetTagsQuery } from "../../../store/doctorApi";
import { useGetGroupsQuery } from "../../../store/groupApi.ts";
import { useGetDoctorsFilterQuery } from "../../../store/rosterApi";
import { useEstimationContext } from "../../../hooks/context/useEstimationContext";
import useHandleOnScheduleAdd from "../../../hooks/estimation/handleOnScheduleAdd";
import { useRefetchContext } from "../../../hooks/context/useRefetchContext.tsx";
import useReloadFirebase from "../../../hooks/useReloadFirebase.tsx";
import { useSeniority } from "../../../store/seniority.state.tsx";
import { useLocation } from "../../../store/location.store.ts";

export const useDoctors = ({
  shiftId,
  type,
  doctorFilterSeniority,
}: {
  shiftId: string | undefined;
  type: "seniority" | "suggested" | "tags" | "slot";
  doctorFilterSeniority?: number;
}) => {
  if (type !== "slot" && !doctorFilterSeniority) {
    throw new Error("Invalid Params");
  }

  const { refetchMap, setRefetchMap } = useRefetchContext();

  const { activeId: activeSeniorityId } = useSeniority();
  const { activeId: activeLocationId, locations } = useLocation();

  const locationDefaultGroupIds = locations
    ?.find((location) => location._id === activeLocationId)
    ?.defaultGroups.map((group: { _id: string }) => group._id);

  const {
    data: doctorsAvailbiltyData,
    isLoading: isDoctorsFilterLoading,
    isFetching: isDoctorsFilterFetching,
    refetch: refetchDoctorFilter,
  } = useGetDoctorsFilterQuery(
    {
      shiftId,
    },
    { skip: !shiftId }
  );

  const {
    data: doctorsData,
    isLoading: isDoctorsLoading,
    isFetching: isDoctorsFetching,
    refetch: refetchDoctor,
  } = useGetDoctorsQuery(
    {
      seniority: 4,
    },
    { skip: type === "slot" || type === "suggested" }
  );
  useReloadFirebase({
    childKey: "doctorListsDate",
    callback: ({ shiftIds }: { shiftIds: string[] }) => {
      const doctorSeniority: Record<string, boolean> = {};
      const doctorSuggestion: Record<string, boolean> = {};
      shiftIds.forEach((shiftId: string) => {
        doctorSeniority[
          JSON.stringify({
            shiftId,
          })
        ] = true;
        doctorSuggestion[
          JSON.stringify({
            shiftId,
          })
        ] = true;
      });

      setRefetchMap((refetchMap) => ({
        ...refetchMap,
        doctorSeniority: { ...refetchMap.doctorSeniority, ...doctorSeniority },
        doctorSuggestion: {
          ...refetchMap.doctorSuggestion,
          ...doctorSuggestion,
        },
      }));
    },
  });

  useEffect(() => {
    if (shiftId) {
      if (type !== "tags") {
        const args = {
          shiftId,
        };
        if (refetchMap.doctorSeniority[JSON.stringify(args)]) {
          refetchDoctorFilter();
          refetchMap.doctorSeniority[JSON.stringify(args)] = false;
          setRefetchMap({
            ...refetchMap,
            doctorSeniority: refetchMap.doctorSeniority,
          });
        }
      }
    }
  }, [
    refetchMap,
    shiftId,
    type,
    doctorFilterSeniority,
    refetchDoctorFilter,
    setRefetchMap,
  ]);

  return {
    isLoading: isDoctorsLoading || isDoctorsFilterLoading,
    isFetching: isDoctorsFetching || isDoctorsFilterFetching,
    refetchDoctorFilter,
    refetchDoctor,
    doctors: (() => {
      switch (type) {
        case "suggested":
          return doctorsData?.doctors
            .filter(
              (doctor: {
                group: any;
                isIdle: any;
                seniority: any;
                _id: string;
              }) =>
                (doctor.seniority?.id ?? doctor.seniority) ===
                  activeSeniorityId &&
                !doctor.isIdle &&
                (locationDefaultGroupIds.length === 0 ||
                  (doctor.group &&
                    locationDefaultGroupIds?.includes(doctor.group._id)))
            )
            .map((doctor: { _id: any }) => {
              return {
                ...(shiftId
                  ? doctorsAvailbiltyData?.find(
                      (dA: { doctor: any }) => dA.doctor === doctor._id
                    )
                  : {}),
                ...doctor,
              };
            })
            .sort(
              (
                a: {
                  availability: string;
                  score: number;
                },
                b: {
                  availability: string;
                  score: number;
                }
              ) => {
                if (a.availability === b.availability) {
                  return a.score! - b.score!;
                }
                if (a.availability === "available") {
                  return -1;
                } else if (b.availability === "available") {
                  return 1;
                } else if (
                  a.availability === "warning" &&
                  b.availability === "unavailable"
                ) {
                  return -1;
                } else {
                  return 1;
                }
              }
            );
        case "seniority":
          return doctorsData?.doctors
            .filter(
              (doctor: { seniority: any; _id: string; isIdle: boolean }) =>
                (doctor.seniority?.id ?? doctor.seniority) ===
                doctorFilterSeniority
            )
            .map((doctor: { _id: any }) => {
              return {
                ...(shiftId
                  ? doctorsAvailbiltyData?.find(
                      (dA: { doctor: any }) => dA.doctor === doctor._id
                    )
                  : {}),
                ...doctor,
              };
            });
        case "slot":
          return doctorsData?.doctors;
        case "tags":
          return doctorsData?.doctors;
      }
    })(),
  };
};

const useData = ({
  activeDate,
  prevDate,
  nextDate,
  searchQuery,
  activeGroups,
  activeSubGroups,
  activeTags,
  setTriggerSearchReset,
}: {
  activeDate: Date;
  prevDate: Date;
  nextDate: Date;
  searchQuery: string;
  activeGroups: { _id: string }[];
  activeSubGroups: { _id: string }[];
  activeTags: string[];
  setTriggerSearchReset: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { editShiftId, doctorFilterSeniority, activeTab } =
    useEstimationContext();

  const { isLoading, isFetching, doctors } = useDoctors({
    shiftId: editShiftId,
    doctorFilterSeniority,
    type: activeTab,
  });

  const { isLoading: isGroupsLoading, data: groupsAll } = useGetGroupsQuery({
    seniority: 4,
    includeDoctors: false,
  });

  const groups = groupsAll?.filter(
    (group: { seniority: any; _id: string }) =>
      doctorFilterSeniority === group.seniority.id
  );

  const { data: tags, isLoading: isTagsLoading } = useGetTagsQuery({});

  const doctorFilerFn = useCallback(
    (doctor: {
      subGroup: any;
      tags: any;
      group: any;
      user: { name: string };
    }) => {
      const searchBoolean =
        searchQuery === "" ||
        doctor.user.name.toLowerCase().startsWith(searchQuery.toLowerCase());

      if (activeTab === "suggested") {
        return searchBoolean;
      }

      if (activeTab === "seniority") {
        const groupBoolean =
          activeGroups.length === 0 ||
          activeGroups.find((group) => {
            return doctor.group && group._id === doctor.group._id;
          });

        const subGroupBoolean =
          activeSubGroups.length === 0 ||
          activeSubGroups.find((subGroup) => {
            return doctor.subGroup && subGroup._id === doctor.subGroup._id;
          });

        return searchBoolean && groupBoolean && subGroupBoolean;
      } else {
        const tagsBoolean =
          activeTags.length === 0 ||
          activeTags.some((tag) =>
            doctor.tags.find(
              (tagObj: { value: string }) => tagObj.value === tag
            )
          );

        if (activeTab === "tags") {
          return searchBoolean && tagsBoolean;
        }
      }
    },
    [activeGroups, activeTab, activeSubGroups, activeTags, searchQuery]
  );

  const filteredDoctors = doctors?.filter(
    (doctor: {
      subGroup: any;
      tags: any;
      group: any;
      user: { name: string };
    }) => {
      return doctorFilerFn(doctor);
    }
  );

  const displayType = (() => {
    if (isLoading) {
      return "loading";
    }
    if (activeTab === "suggested" && !editShiftId) {
      return "selectShift";
    }
    return filteredDoctors && filteredDoctors.length > 0
      ? "displayData"
      : "noData";
  })();

  const handleOnScheduleAdd = useHandleOnScheduleAdd({
    activeDate,
    prevDate,
    nextDate,
    setTriggerSearchReset,
  });

  return {
    isDoctorsLoading: isLoading,
    isDoctorsFetching: isFetching,
    displayType,
    isGroupsLoading,
    isTagsLoading,
    doctors: filteredDoctors,
    tags,
    groups,
    handleOnScheduleAdd,
  };
};

export default useData;
