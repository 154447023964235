import { useEffect, useState } from "react";
import Slider from "rc-slider";
import { useDroppable } from "@dnd-kit/core";
import SlotPill from "./SlotPill.tsx";
import HorizontalScrollContainer from "../HorizontalScrollContainer.tsx";
import { INavTab } from "../NavTabs/index.tsx";
import DoctorCard from "../Cards/SmallDoctorCard.tsx";
import { useSeniority } from "../../store/seniority.state.tsx";
import addOrRemove from "../../utils/addOrRemoveArray.ts";

import { SENIORITY_COLOR_CODE } from "../../constants.ts";
import { useGetGroupsQuery } from "../../store/groupApi.ts.ts";
import { useGetTagsQuery } from "../../store/doctorApi.ts";
import { useEstDndContext } from "../../hooks/context/useEstDndContext.tsx";
import { Doctor } from "../../interface/doctor.ts";

type Mode = "group" | "tag" | "users" | "experience" | "efficiency";

const getSeniorityLabel = (
  seniority: INavTab
): "Senior" | "Mid Level" | "Junior" => {
  switch (seniority.id) {
    case 1:
      return "Senior";
    case 2:
      return "Mid Level";
    case 3:
      return "Junior";
    default:
      throw new Error("Invalid Seniority");
  }
};

const getSeniorityColor = (seniority: number): string => {
  return SENIORITY_COLOR_CODE[seniority.toString() as "1" | "2" | "3"];
};

const ITEMS: { label: string; key: Mode; colorCode: string }[] = [
  { label: "Group", key: "group", colorCode: "#8E74A7" },
  { label: "Tag", key: "tag", colorCode: "#9B5140" },
  { label: "Experience", key: "experience", colorCode: "#3E7C76" },
  { label: "Efficiency", key: "efficiency", colorCode: "#529A4D" },
  { label: "Users", key: "users", colorCode: "#496F92" },
];

const FilterSlot = ({
  shiftId,
  slotIndex,
  filterState,
  setFilterState,
}: {
  shiftId: string;
  slotIndex: number;
  filterState: {
    seniority?: number;
    groups: string[];
    subGroups: string[];
    tags: string[];
    experience: number;
    efficiency: number;
  };
  setFilterState: (filter: {
    seniority?: number;
    groups: string[];
    subGroups: string[];
    tags: string[];
    experience: number;
    efficiency: number;
  }) => void;
}) => {
  // convert this component into FilterSlot
  const { seniorities } = useSeniority();
  const { activeFilterDoctors, setActiveFilterDoctors } = useEstDndContext();

  const checkIfFilterEnabled = (mode: Mode): boolean => {
    switch (mode) {
      case "group":
        return Boolean(filterState.seniority);
      case "tag":
        return filterState.tags.length > 0;
      case "efficiency":
        return filterState.efficiency > 0;
      case "experience":
        return filterState.experience > 0;
      case "users":
        return (
          (activeFilterDoctors[`${shiftId}-${slotIndex}`]?.length ?? 0) > 0
        );
    }
  };

  const getCurrentGroup = () => {
    return checkIfFilterEnabled("users")
      ? "users"
      : ITEMS.find((item) => {
          return checkIfFilterEnabled(item.key);
        })?.key ?? "group";
  };

  const [mode, setMode] = useState<Mode>(getCurrentGroup);

  // temporary useEffect until refactor
  useEffect(() => {
    setMode(getCurrentGroup());
  }, [activeFilterDoctors]);

  const { setNodeRef, isOver } = useDroppable({
    id: `doctor-${shiftId}-${slotIndex}`,
    data: {
      type: "doctorCard",
      key: `${shiftId}-${slotIndex}`,
    },
  });

  const {
    isLoading: isGroupsLoading,
    isFetching: isGroupsFetching,
    data: groups,
  } = useGetGroupsQuery({ seniority: 4, includeDoctors: false });

  const { data: tags, isLoading: isTagsLoading } = useGetTagsQuery({});

  const subGroups: any[] | undefined = groups
    ?.filter((group: { _id: string }) => filterState.groups.includes(group._id))
    .map((group: { subGroups: any[] }) => group.subGroups)
    .flat();

  const tagSplit = Math.floor(tags.length / 3);

  return (
    <div>
      <HorizontalScrollContainer className="mt-2 mb-1 mx-1 !gap-x-1">
        {ITEMS.map((item: { label: string; colorCode: string; key: Mode }) => {
          const isFilterEnabled = checkIfFilterEnabled(item.key);
          const isFilterEnabledUser = checkIfFilterEnabled("users");

          const isRestFilterEnabled = isFilterEnabled && !isFilterEnabledUser;

          const type = (() => {
            if (mode !== item.key && isRestFilterEnabled) {
              return "outline";
            }
            return "full";
          })();

          return (
            <SlotPill
              label={item}
              type={type}
              isActive={mode === item.key || isRestFilterEnabled}
              onClick={() => {
                if (!isFilterEnabledUser && item.key !== "users") {
                  setMode(item.key);
                } else if (item.key === "users") {
                  setMode(item.key);
                }
              }}
            />
          );
        })}
      </HorizontalScrollContainer>
      {mode === "group" && (
        <>
          <HorizontalScrollContainer className={`my-1 mx-1 !gap-x-1`}>
            {seniorities.map((seniority) => (
              <SlotPill
                label={{
                  label: getSeniorityLabel(seniority),
                  colorCode: getSeniorityColor(seniority.id),
                  key: seniority.id.toString(),
                }}
                isActive={filterState.seniority === seniority.id}
                onClick={() => {
                  setFilterState({
                    ...filterState,
                    seniority:
                      filterState.seniority === seniority.id
                        ? undefined
                        : seniority.id,
                    groups: [],
                    subGroups: [],
                  });
                }}
              />
            ))}
          </HorizontalScrollContainer>
          <HorizontalScrollContainer
            className={`my-1 mx-1 !gap-x-1 min-h-[26px] ${
              isGroupsLoading || isGroupsFetching
                ? "animate-pulseFast rounded-lg bg-lightGreen2"
                : ""
            }`}
          >
            {groups
              ?.filter(
                (group: { seniority: { id: number } }) =>
                  filterState.seniority === group.seniority.id
              )
              .map(
                (group: {
                  title: string;
                  _id: string;
                  seniority: { id: number };
                }) => (
                  <SlotPill
                    label={{
                      label: group.title,
                      colorCode: getSeniorityColor(group.seniority.id),
                      key: group._id,
                    }}
                    isActive={filterState.groups.includes(group._id)}
                    onClick={() => {
                      setFilterState({
                        ...filterState,
                        groups: addOrRemove(filterState.groups, group._id),
                      });
                    }}
                  />
                )
              ) ?? []}
          </HorizontalScrollContainer>
          <HorizontalScrollContainer
            className={`my-1 mx-1 !gap-x-1 min-h-[26px] ${
              isGroupsLoading || isGroupsFetching
                ? "animate-pulseFast rounded-lg bg-lightGreen2"
                : ""
            }`}
          >
            {[
              subGroups?.map((subGroup) => (
                <SlotPill
                  label={{
                    label: subGroup.title,
                    colorCode: getSeniorityColor(
                      groups.find(
                        (group: { _id: string }) =>
                          group._id === subGroup.groupId
                      ).seniority.id
                    ),
                    key: subGroup._id,
                  }}
                  type="outline"
                  isActive={filterState.subGroups.includes(subGroup._id)}
                  onClick={() => {
                    setFilterState({
                      ...filterState,
                      subGroups: addOrRemove(
                        filterState.subGroups,
                        subGroup._id
                      ),
                    });
                  }}
                />
              )) ?? [],
            ]}
          </HorizontalScrollContainer>
        </>
      )}
      {mode === "users" && (
        <>
          <HorizontalScrollContainer className={`py-1 px-1 !gap-x-1`}>
            <div className="grid grid-rows-3 grid-flow-col gap-2">
              <div
                ref={setNodeRef}
                className={`row-span-1 w-20 ${
                  isOver ? "border-dashed border-2 rounded-lg" : ""
                } `}
              >
                <DoctorCard name={"+"} />
              </div>
              {activeFilterDoctors[`${shiftId}-${slotIndex}`]?.map((doctor) => (
                <div
                  className="row-span-1 w-20"
                  onClick={() => {
                    setActiveFilterDoctors({
                      [`${shiftId}-${slotIndex}`]: [
                        ...activeFilterDoctors[
                          `${shiftId}-${slotIndex}`
                        ].filter((d) => d._id !== doctor._id),
                      ],
                    });
                  }}
                >
                  <DoctorCard
                    name={doctor.user.name}
                    nickName={doctor.user.nickName}
                    seniority={doctor.seniority?.id ?? doctor.seniority}
                  />
                </div>
              )) ?? []}
            </div>
          </HorizontalScrollContainer>
        </>
      )}
      {mode === "tag" && (
        <>
          {Array(3)
            .fill(undefined)
            .map((_, index) => {
              const startIndex = tagSplit * index;
              const endIndex =
                startIndex + tagSplit > tags.length
                  ? tags.length
                  : startIndex + tagSplit;
              return (
                <HorizontalScrollContainer
                  className={`my-1 mx-1 !gap-x-1 ${
                    isTagsLoading
                      ? "animate-pulseFast rounded-lg bg-lightGreen2"
                      : ""
                  }`}
                >
                  {tags
                    .slice(startIndex, endIndex)
                    .map((tag: { value: string; _id: string }) => (
                      <SlotPill
                        label={{
                          label: tag.value,
                          colorCode: "#9B5140",
                          key: tag._id,
                        }}
                        type="outline"
                        isActive={filterState.tags.includes(tag._id)}
                        onClick={() => {
                          setFilterState({
                            ...filterState,
                            tags: addOrRemove(filterState.tags, tag._id),
                          });
                        }}
                      />
                    ))}
                </HorizontalScrollContainer>
              );
            })}
        </>
      )}
      {mode === "experience" && (
        <div className="mt-5 mx-4">
          <Slider
            styles={{
              track: { height: "8px", backgroundColor: "#DAF4F3" },
              rail: { height: "8px", backgroundColor: "#DAF4F3" },
              handle: {
                backgroundColor: "#3E7C76",
                top: "42%",
                height: "15px",
                width: "15px",
              },
            }}
            min={0}
            max={50}
            value={filterState.experience}
            onChange={(event) => {
              setFilterState({
                ...filterState,
                experience: event as number,
              });
            }}
          />
          <div className="flex justify-between mx-4 mt-2">
            <div className="text-teal6 font-semibold">0</div>
            <div className="text-teal6 font-semibold">
              {filterState.experience}+ years
            </div>
            <div className="text-teal6 font-semibold">50</div>
          </div>
        </div>
      )}
      {mode === "efficiency" && (
        <div className="mt-5 mx-4">
          <Slider
            styles={{
              track: { height: "10px", backgroundColor: "#D3F3D1" },
              rail: { height: "10px", backgroundColor: "#D3F3D1" },
              handle: {
                backgroundColor: "#529A4D",
                top: "42%",
                height: "15px",
                width: "15px",
              },
            }}
            min={0}
            max={10}
            value={filterState.efficiency}
            onChange={(event) => {
              setFilterState({
                ...filterState,
                efficiency: event as number,
              });
            }}
          />
          <div className="flex justify-center  mx-4 mt-2">
            <div className="text-green9 font-semibold">
              {filterState.efficiency}/10
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterSlot;
