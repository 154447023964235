import { useDebouncedCallback } from "use-debounce";
import { Dna } from "react-loader-spinner";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";

import useDoctorCardAnimate, { getRowCol } from "./hooks/doctorCardAnimate.tsx";
import UserProfile from "./UserManagement/UserProfile.tsx";
import GroupsSideBar from "./GroupManagement/GroupsSideBar.tsx";
import SubGroupBar from "./GroupManagement/SubGroupBar.tsx";
import GroupBar from "./GroupManagement/GroupBar.tsx";
import HorizontalScrollContainer from "../HorizontalScrollContainer";
import DoctorCard from "../Cards/DoctorCard.tsx";
import TagPill from "../Pills/Pill";
import { useSeniority } from "../../store/seniority.state";
import api from "../../store/api";
import { useModal } from "../../hooks/useModal";
import { useGetGroupsQuery } from "../../store/groupApi.ts";
import ConfirmationModal from "../Modals/Confirmation.tsx";
import { useRunOnChange } from "../../hooks/runOnChange.tsx";

const COLUMNS = 4;

const GroupManagementTab = () => {
  const { activeId: activeSeniorityId } = useSeniority();

  // Move this to group management context
  const [activeGroupId, setActiveGroupId] = useState<any>("");
  const [activeGroupData, setActiveGroupData] = useState<any>("");
  const [activeSubGroupData, setActiveSubGroupData] = useState<any>("");
  const [activeSubGroupId, setActiveSubGroupId] = useState<any>("");
  const [isUnassignedActive, setIsUnassignedActive] = useState<boolean>(false);
  const [unassignedDoctorsInGroup, setUnassignedDoctors] = useState<any[]>([]);
  const [doctorTags, setDoctorTags] = useState<any[]>([]);
  const [triggerReload, setTriggerReload] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchType, setSearchType] = useState<string>("name");
  const [selectedUser, setSelectedUser] = useState<any | undefined>();

  const { openModal, closeModal } = useModal();

  const [activeTags, setActiveTags] = useState<any>([]);

  const assignActiveTags = (tags: any) => {
    if (activeTags.includes(tags) && activeTags.length > 0) {
      const tagArray = [...activeTags];
      const i = tagArray.indexOf(tags);
      tagArray.splice(i, 1);
      setActiveTags(tagArray);
    } else {
      setActiveTags((prev: any) => [...prev, tags]);
    }
  };

  // convert this into the rtk query
  const {
    fetchFn: getUnassignedDoctorsInGroup,
    isLoading: isUnassignedDoctorsLoading,
  } = api.group.useGetUnassignedDoctorsInGroup();

  const {
    fetchFn: deleteDoctorFromGroup,
    isLoading: isRemovingDoctorFromGroup,
  } = api.group.useDeleteDoctorFromGroup();

  const {
    fetchFn: deleteFromSubGroup,
    isLoading: isRemovingDoctorFromSubGroup,
  } = api.group.useDeleteDoctorFromSubGroup();

  const {
    fetchFn: getUnassignedDoctors,
    isLoading: isDoctorsLoading,
    data: unassignedDoctors,
  } = api.doctor.useGetUnassignedDoctors();

  const debouncedGetUnassignedDoctors = useDebouncedCallback(
    (args) => getUnassignedDoctors(args),
    500
  );

  useEffect(() => {
    debouncedGetUnassignedDoctors({
      params: {
        seniority: activeSeniorityId,
        max: 200,
      },
    });
  }, [activeSeniorityId, searchQuery, searchType, triggerReload]);

  const [doctorsList, setDoctorsList] = useState<any[]>([]);

  // Update API Queries

  const {
    isLoading: isGroupsLoading,
    isFetching: isGroupsFetching,
    data: groups,
    refetch: refetchGroups,
  } = useGetGroupsQuery({ seniority: activeSeniorityId });

  useRunOnChange(activeSeniorityId, () => {
    setActiveSubGroupId("");
    setActiveGroupId("");
    setIsUnassignedActive(false);
  });

  useEffect(() => {
    refetchGroups();
  }, [triggerReload]);

  const setUniqueDoctorTags = (doctors: any[] | undefined) => {
    const tagSet = new Set();
    setDoctorTags(
      [...(doctors ?? [])]
        .map((doc) => doc.tags)
        .flat()
        .filter((tag) => {
          const exists = tagSet.has(tag.id || tag._id);
          tagSet.add(tag.id || tag._id);
          return !exists;
        })
    );
  };

  // Remove this use effect and add them to the event handlers
  useEffect(() => {
    if (activeGroupId === "" && !isGroupsLoading && groups) {
      setActiveGroupId(groups[0]?.id ?? "");
      setActiveGroupData(groups[0]);
      setActiveSubGroupData(undefined);
      setActiveTags([]);
      setDoctorsList([...(groups[0]?.doctors ?? [])]);
      setUniqueDoctorTags(groups[0]?.id?.doctors);
    } else if (activeSubGroupId === "" && !isGroupsLoading && groups) {
      const foundGroup = groups.find(
        (group: { id: any }) => group.id === activeGroupId
      );
      setActiveGroupData(foundGroup);
      setDoctorsList([...(foundGroup?.doctors ?? [])]);
      setUniqueDoctorTags(foundGroup?.doctors);
    } else if (!isGroupsLoading && groups) {
      const foundGroup = groups.find(
        (group: { id: any }) => group.id === activeGroupId
      );
      const foundSubGroup = foundGroup?.subGroups?.find(
        (subGroup: { id: any }) => subGroup.id === activeSubGroupId
      );
      setActiveTags([]);
      setActiveGroupData(foundGroup);
      setActiveSubGroupData(foundSubGroup);
      setDoctorsList([...(foundSubGroup?.doctors ?? [])]);
      setUniqueDoctorTags(foundSubGroup?.doctors);
    } else if (!isGroupsLoading) {
      setActiveTags([]);
      setActiveGroupData(undefined);
      setActiveSubGroupData(undefined);
      setDoctorsList([]);
      setUnassignedDoctors([]);
      setDoctorTags([]);
      setActiveGroupId("");
      setActiveSubGroupId("");
      setIsUnassignedActive(false);
    }
  }, [groups]);

  useEffect(() => {
    getUnassignedDoctorsInGroup({ params: { groupId: activeGroupId } }).then(
      (resp) => setUnassignedDoctors([...(resp.unwrap().data ?? [])])
    );
  }, [activeGroupId, triggerReload, activeSeniorityId]);

  const filteredList = doctorsList
    .filter((doctor) => {
      if (searchType === "name") {
        return doctor.user.name
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      } else {
        return doctor.tags.some((tag: any) =>
          tag.value.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
    })
    .filter(
      (doctor) =>
        doctor.tags.some((tag: any) =>
          activeTags.includes(tag.id || tag._id)
        ) || activeTags.length === 0
    );

  const { finalDoctors, profileColPos, profileRowPos, type } =
    useDoctorCardAnimate({
      selectedDoctorIndex: selectedUser
        ? filteredList.findIndex((value) => value._id === selectedUser._id)
        : -1,
      doctors: filteredList,
      enable: Boolean(selectedUser),
      columns: COLUMNS,
    });

  if (isGroupsLoading || isGroupsFetching) {
    return (
      <div className="flex items-center justify-center h-full">
        <Dna height={100} width={100} />
      </div>
    );
  }

  return (
    <div className="grid grid-cols-9 pt-4 h-[99vh] flex-grow overflow-auto">
      <GroupsSideBar
        groups={groups}
        activeGroupId={activeGroupId}
        unassignedDoctors={unassignedDoctors}
        triggerReload={triggerReload}
        refetchGroups={refetchGroups}
        setIsUnassignedActive={setIsUnassignedActive}
        setActiveGroupId={setActiveGroupId}
        setActiveSubGroupId={setActiveSubGroupId}
        setActiveGroupData={setActiveGroupData}
        setDoctorsList={setDoctorsList}
        setActiveTags={setActiveTags}
        setUniqueDoctorTags={setUniqueDoctorTags}
        setTriggerReload={setTriggerReload}
        setSelectedUser={setSelectedUser}
      />
      {groups && groups.length > 0 && (
        <div className="col-span-7 pb-4 px-4">
          <SubGroupBar
            activeGroupData={activeGroupData}
            unassignedDoctorsInGroup={unassignedDoctorsInGroup}
            triggerReload={triggerReload}
            activeSubGroupId={activeSubGroupId}
            isUnassignedActive={isUnassignedActive}
            setUniqueDoctorTags={setUniqueDoctorTags}
            setDoctorsList={setDoctorsList}
            setTriggerReload={setTriggerReload}
            setActiveTags={setActiveTags}
            setIsUnassignedActive={setIsUnassignedActive}
            setActiveSubGroupId={setActiveSubGroupId}
            setActiveSubGroupData={setActiveSubGroupData}
          />

          <GroupBar
            activeGroupId={activeGroupId}
            activeSubGroupId={activeSubGroupId}
            activeGroupData={activeGroupData}
            activeSubGroupData={activeSubGroupData}
            unassignedDoctors={unassignedDoctors}
            triggerReload={triggerReload}
            unassignedDoctorsInGroup={unassignedDoctorsInGroup}
            doctorTags={doctorTags}
            activeTags={activeTags}
            assignActiveTags={assignActiveTags}
            setSearchQuery={setSearchQuery}
            setSearchType={setSearchType}
            setActiveGroupId={setActiveGroupId}
            setActiveSubGroupId={setActiveSubGroupId}
            setActiveGroupData={setActiveGroupData}
            setTriggerReload={setTriggerReload}
          />
          <div className="py-6 px-2 z-0">
            <HorizontalScrollContainer className="3xl:gap-x-4 gap-x-0 z-0">
              {doctorTags.map((tag) => (
                <>
                  <TagPill
                    name={tag.value}
                    key={tag.id || tag._id}
                    className={"3xl:scale-100 scale-75"}
                    isActive={activeTags.includes(tag.id || tag._id)}
                    onClick={() => assignActiveTags(tag.id || tag._id)}
                  />
                </>
              ))}
            </HorizontalScrollContainer>
          </div>
          <div className="grid grid-cols-4 gap-4 overflow-hidden py-4">
            {finalDoctors.map(
              (
                doctor: {
                  user: {
                    email: string;
                    name: string;
                  };
                  experience: number;
                  efficiency: number;
                  groups: any[];
                  subGroups: any[];
                  tags: any[];
                  _id: string;
                },
                index: number
              ) => {
                const { row, col } = getRowCol(COLUMNS)(index);

                const userSubGroup = selectedUser
                  ? activeGroupData.subGroups.find(
                      (subGroup: { doctors: any[] }) =>
                        subGroup.doctors.find(
                          (doctor: { _id: any }) =>
                            doctor._id === selectedUser._id
                        )
                    )
                  : undefined;

                return (
                  <>
                    {[1, 2, 3, 4].includes(row) &&
                    [0, 1, 2, 3].includes(col) &&
                    selectedUser &&
                    profileRowPos === row &&
                    profileColPos === col ? (
                      <AnimatePresence>
                        <UserProfile
                          type={{ loc: type.y, page: "group-management" }}
                          email={selectedUser.user.email}
                          experience={selectedUser.experience}
                          efficiency={selectedUser.efficiencyScore}
                          groups={[activeGroupData]}
                          subGroups={userSubGroup ? [userSubGroup] : []}
                          tags={selectedUser.tags}
                        />
                      </AnimatePresence>
                    ) : doctor ? (
                      <div className="h-[70px] col-span-1 row-span-1 relative">
                        {Boolean(selectedUser?._id === doctor._id) && (
                          <>
                            <div
                              className={`absolute h-[320%] w-[205%] bg-white ${
                                type.y === "top" ? "bottom-0" : "top-0"
                              } ${
                                type.x === "right" ? "left-0" : "right-0"
                              } z-[3]`}
                            ></div>
                          </>
                        )}
                        <DoctorCard
                          className="z-10 relative h-full"
                          type="group-management"
                          name={doctor.user.name}
                          doctor={doctor}
                          doctorId={doctor._id.toString()}
                          isActive={Boolean(selectedUser?._id === doctor._id)}
                          isFetching={false}
                          onClickHandler={() => {
                            if (
                              !selectedUser ||
                              selectedUser._id !== doctor._id
                            ) {
                              setSelectedUser(doctor);
                            } else {
                              setSelectedUser(undefined);
                            }
                          }}
                          seniority={
                            activeSeniorityId.toString() as "1" | "2" | "3"
                          }
                          deleteHandler={() => {
                            openModal(
                              <ConfirmationModal
                                message={`Do you want to remove ${
                                  doctor.user.name
                                } doctor from ${
                                  activeGroupId && activeSubGroupId ? "Sub" : ""
                                }Group?`}
                                onConfirm={async () => {
                                  if (activeGroupId && activeSubGroupId) {
                                    const removeDocSubGroup =
                                      await deleteFromSubGroup({
                                        data: {
                                          doctorId: doctor._id,
                                          subGroupId: activeSubGroupId,
                                        },
                                      });

                                    const { isSuccess, isError, error } =
                                      removeDocSubGroup.unwrap();

                                    if (isSuccess) {
                                      toast.success(
                                        "Doctor removed from subgroup successfully"
                                      );

                                      setDoctorsList(
                                        doctorsList.filter(
                                          (doc) => doc._id !== doctor._id
                                        )
                                      );
                                    }

                                    if (isError) {
                                      toast.error(
                                        error?.response?.data?.message
                                      );
                                    }
                                  }

                                  if (activeGroupId && !activeSubGroupId) {
                                    const removeDocGroup =
                                      await deleteDoctorFromGroup({
                                        data: {
                                          doctors: [doctor._id],
                                          groupId: activeGroupId,
                                        },
                                      });

                                    const { isSuccess, isError, error } =
                                      removeDocGroup.unwrap();

                                    if (isSuccess) {
                                      toast.success(
                                        "Doctor removed from group successfully"
                                      );

                                      setDoctorsList(
                                        doctorsList.filter(
                                          (doc) => doc._id !== doctor._id
                                        )
                                      );
                                    }

                                    if (isError) {
                                      toast.error(
                                        error?.response?.data?.message
                                      );
                                    }
                                  }
                                  setTriggerReload(!triggerReload);
                                  closeModal();
                                }}
                              />
                            );
                          }}
                        />
                      </div>
                    ) : (
                      <div className="h-[70px] col-span-1 row-span-1 z-10 relative"></div>
                    )}
                  </>
                );
              }
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default GroupManagementTab;
