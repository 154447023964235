import { LegacyRef } from "react";

import DeleteShift from "./Modals/DeleteShift";
import { Minus } from "../Icons/Minus";
import Plus from "../Icons/Plus";
import Trash from "../Icons/Trash";
import EditTwo from "../Icons/EditTwo";
import {
  useDeleteShiftMutation,
  useUpdateShiftMutation,
} from "../../store/shiftsApi";
import { useModal } from "../../hooks/useModal";
import { useEstimationContext } from "../../hooks/context/useEstimationContext";
import { formatTime } from "../../utils/formatTime";
import useHandleSuccessErrors from "../../hooks/handleSuccessErrors";
import useHandleOnScheduleAdd from "../../hooks/estimation/handleOnScheduleAdd";
import { getDateRange } from "../../utils/date";
import { ShiftType } from "../../interface/shift";

type ShiftMinimizeProps = {
  slotsFilled: number;
  slotsCount: number;
  time: { from: string; to: string };
  type: ShiftType;
  shiftId: string;
  dateBlockType: "inFocus" | "dayBefore" | "dayAfter";
  refetchShifts: () => Promise<void>;
  resetAddShift: () => void;
  date: Date;
  isFetching: boolean;
  innerRef: LegacyRef<HTMLDivElement> | undefined;
};

const getEditColor = (type: "inFocus" | "dayBefore" | "dayAfter") => {
  if (type === "inFocus") {
    return "bg-green1 text-white";
  }
  if (type === "dayBefore") {
    return "bg-orange3 text-black";
  }
  if (type === "dayAfter") {
    return "bg-blue3 text-black";
  }
};

const ShiftMinimize = ({
  slotsFilled,
  slotsCount,
  time,
  type,
  shiftId,
  dateBlockType,
  refetchShifts,
  resetAddShift,
  isFetching,
  date,
  innerRef,
}: ShiftMinimizeProps) => {
  const { openModal, closeModal } = useModal();
  const [
    updateShift,
    {
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateShiftMutation();

  const {
    editShiftMinId,
    setEditShiftMinId,
    setEditShiftId,
    setResponsiveWindow,
    responsiveWindow,
    setMonitorActiveId,
  } = useEstimationContext();

  const onShiftTypeUpdate = useHandleOnScheduleAdd({
    ...getDateRange(date),
    shouldRefetchShiftRequests: false,
  });

  useHandleSuccessErrors({
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
    error: updateError,
    successMessage: "Shift updated successfully !",
    successFunction: async () => {
      // temporary remove this hook and move to handle response
      await onShiftTypeUpdate();
      if (responsiveWindow.type === "editShift") {
        setResponsiveWindow({
          type: "dateView",
          props: {},
        });
      }
    },
  });

  // const { triggerDoctorPrefetch } = usePrefetchContext();

  const [
    deleteShift,
    {
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
      error: deleteError,
    },
  ] = useDeleteShiftMutation();

  useHandleSuccessErrors({
    isSuccess: isDeleteSuccess,
    isError: isDeleteError,
    error: deleteError,
    successMessage: "Shift deleted successfully !",
    successFunction: async () => {
      await refetchShifts();
      setEditShiftId(undefined);
    },
  });

  const isLoading = isDeleteLoading || isUpdateLoading || isFetching;

  return (
    <div ref={innerRef}>
      <div
        className={`px-[16px] py-[12px] ${
          editShiftMinId === shiftId
            ? `${getEditColor(dateBlockType)}  rounded-t-xl`
            : "bg-white text-black1 rounded-xl"
        } text-sm  font-medium items-center transition-all ${
          isLoading ? "animate-pulseFast" : ""
        }`}
      >
        <div
          onClick={() => {
            setEditShiftMinId(
              shiftId === editShiftMinId && editShiftMinId ? undefined : shiftId
            );
            resetAddShift();
          }}
          className="mb-4 cursor-pointer flex justify-center relative"
        >
          {editShiftMinId !== shiftId && type !== "normal" && (
            <div
              className={`text-[9px] font-medium text-white ${
                type === "onCall" ? "bg-orange4" : "bg-blue5"
              } px-1 rounded-lg absolute left-1`}
            >
              {type === "onCall" ? "on call" : "standby"}
            </div>
          )}
          <div>{`${formatTime(time.from)} - ${formatTime(time.to)}`}</div>
        </div>
        <div className="flex justify-between gap-1">
          {Array(slotsFilled)
            .fill(0)
            .map((_, index) => (
              <div
                key={index}
                className={`h-[3px] flex-grow rounded-sm ${
                  editShiftMinId === shiftId ? "bg-[#FFFFFF]" : "bg-[#3C4B35]"
                }`}
              />
            ))}
          {Array(slotsCount - slotsFilled)
            .fill(0)
            .map((_, index) => (
              <div
                key={index}
                className={`h-[3px] flex-grow rounded-sm ${
                  editShiftMinId === shiftId && dateBlockType === "inFocus"
                    ? "bg-[#97A56A]"
                    : "bg-[#BDBDBD]"
                }`}
              />
            ))}
        </div>
      </div>
      {editShiftMinId === shiftId && (
        <div
          className={`flex justify-between items-center px-3 py-2 rounded-b-xl bg-white transition-all
          }`}
        >
          <div
            className={`${!isLoading ? "cursor-pointer" : ""}`}
            onClick={async () => {
              if (!isLoading) {
                if (slotsFilled > 0) {
                  openModal(
                    <DeleteShift
                      deleteShift={async () => {
                        await deleteShift({ shiftId });
                      }}
                      closeModal={closeModal}
                    />
                  );
                } else {
                  await deleteShift({ shiftId });
                }
              }
            }}
          >
            <Trash />
          </div>{" "}
          <button
            className={`text-[10px] font-medium ${
              type === "onCall"
                ? "text-white bg-orange4"
                : "text-black bg-gray2"
            } px-2 py-0.5 rounded-lg`}
            onClick={async () => {
              await updateShift({
                shiftId,
                type: type === "onCall" ? "normal" : "onCall",
              });
            }}
          >
            on call
          </button>
          <div className="flex">
            <div
              className={`${!isLoading ? "cursor-pointer" : ""}`}
              onClick={async () => {
                if (!isLoading) {
                  await updateShift({
                    shiftId,
                    totalDoctorsRequired: slotsCount - 1,
                  });
                }
              }}
            >
              <Minus stroke={"stroke-[#9ca3af]"} />
            </div>
            <div
              className={`${!isLoading ? "cursor-pointer" : ""}`}
              onClick={async () => {
                if (!isLoading && slotsCount < 8) {
                  await updateShift({
                    shiftId,
                    totalDoctorsRequired: slotsCount + 1,
                  });
                }
              }}
            >
              <Plus color={"gray"} />
            </div>
          </div>{" "}
          <button
            className={`text-[10px] font-medium ${
              type === "standBy" ? "text-white bg-blue5" : "text-black bg-gray2"
            } px-2 py-0.5 rounded-lg blue5`}
            onClick={async () => {
              await updateShift({
                shiftId,
                type: type === "standBy" ? "normal" : "standBy",
              });
            }}
          >
            standby
          </button>
          <div
            className={`${slotsFilled > 0 ? "bg-gray2 rounded" : ""}${
              isLoading || slotsFilled > 0 ? "" : "cursor-pointer"
            }`}
            onClick={() => {
              if (!isLoading && slotsFilled === 0) {
                setMonitorActiveId(undefined);
                setResponsiveWindow({
                  type: "editShift",
                  props: {
                    shiftId,
                    date,
                    time,
                    slotsCountProp: slotsCount,
                    slotsFilled,
                  },
                });
              }
            }}
          >
            <EditTwo />
          </div>{" "}
        </div>
      )}
    </div>
  );
};

export default ShiftMinimize;
