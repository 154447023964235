import React, { useState } from "react";
import { DateTime } from "luxon";
import { HiDotsHorizontal, HiCheckCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { IoMdLock } from "react-icons/io";
import { useDispatch } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import { RotatingSquare } from "react-loader-spinner";

import ContextMenu from "../ContextMenu";
import Typography from "../Typography";
import { useModal } from "../../hooks/useModal";
import RoundedCalendar from "./RoundedCalander";
import { useSeniority } from "../../store/seniority.state";
import { useLocation } from "../../store/location.store";
import { TIME_ZONE } from "../../constants";
import { formatDateAPI } from "../../utils/formatTime";
import {
  useCopySettingsMutation,
  useDeleteSchedulesDateMutation,
  useDeleteShiftsDateMutation,
  useGetCalendarQuery,
} from "../../store/locationApi";

import AddCancel from "../Icons/AddCancel";
import { Check } from "../Icons/Check";
import handleResponse from "../../utils/handleResponse";
import { shiftsApi } from "../../store/shiftsApi";

interface CalenderCardProps extends React.HTMLAttributes<HTMLDivElement> {
  isEstimated?: boolean;
  day: string;
  date: Date;
  monthName: string;
  isDisabled?: boolean;
  skeleton?: boolean;
  onClick?: () => void;
  summary?: object;
  className?: string;
  others?: React.ReactNode;
  showContextMenu?: boolean;
  isFinalized?: boolean;
  isOpenRequest: boolean;
  isActive: boolean;
  url: string;
  menuLoc: "left" | "right";
}

const bgClasses: Record<string, { active: string; inActive: string }> = {
  default: {
    inActive: "bg-white shadow-xl",
    active: "bg-secondary shadow-xl",
  },
  openToRequest: {
    inActive: "bg-purple2 shadow-xl",
    active: "bg-purple1 shadow-xl",
  },
};

const MenuItemWCalendar = ({
  name,
  date,
  disabledDates,
  yellowDates,
  onSubmit,
}: {
  name: string;
  yellowDates: Date[];
  disabledDates: Date[];
  date: Date;
  onSubmit: (dates: Date[]) => Promise<void>;
}) => {
  const { openModal, closeModal } = useModal();

  return (
    <button
      className="hover:bg-slate-300 text-start p-3"
      onClick={() => {
        openModal(
          <RoundedCalendar
            onCancel={() => closeModal()}
            disableYellowDates={true}
            initial={{
              yellowDates,
              disabledDates,
              month: date.getMonth() + 1,
              year: date.getFullYear(),
            }}
            showFooter={true}
            onSubmit={onSubmit}
          />
        );
      }}
    >
      <Typography tag="span" className="whitespace-nowrap p-3 font-semibold">
        {name}
      </Typography>
    </button>
  );
};

const MenuItemWithConfirm = ({
  name,
  isLoading,
  onSubmit,
}: {
  name: string;
  isLoading: boolean;
  onSubmit: () => Promise<void>;
}) => {
  const [confirm, setConfirm] = useState<boolean>(false);
  return (
    <>
      <button
        className="hover:bg-slate-300 text-start p-3"
        onClick={() => {
          setConfirm(true);
        }}
      >
        <Typography tag="span" className="whitespace-nowrap p-3 font-semibold">
          {name}
        </Typography>
      </button>
      <AnimatePresence>
        {confirm && (
          <motion.div
            key={"confirm"}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="bg-primary flex justify-between p-3"
          >
            <div
              className="rounded-xl bg-orange1 hover:bg-[#70470B] px-4 py-1.5 w-[43%] flex justify-center svg-hover-white"
              onClick={() => setConfirm(false)}
            >
              <AddCancel type="cancel" stroke="#DF9C28" />
            </div>
            <div
              className="rounded-xl bg-green1 hover:bg-white px-4 py-1.5 w-[43%] flex justify-center items-center svg-hover-black-2"
              onClick={async () => {
                await onSubmit();
                setConfirm(false);
              }}
            >
              {isLoading ? (
                <RotatingSquare height={"10px"} width={"10px"} />
              ) : (
                <Check stroke="white" />
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

const CalenderCard = (props: CalenderCardProps) => {
  const {
    isEstimated = false,
    isFinalized = false,
    isOpenRequest,
    isActive,
    day,
    date,
    isDisabled = false,
    monthName,
    summary,
    onClick,
    className,
    skeleton = false,
    others,
    showContextMenu = true,
    url,
    menuLoc,
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { closeModal } = useModal();

  const { activeId: activeSeniorityId } = useSeniority();
  const { activeId: activeLocationId } = useLocation();

  const [copySettings] = useCopySettingsMutation();
  const [deleteSchedules, { isLoading: isDeleteSchedulesLoading }] =
    useDeleteSchedulesDateMutation();
  const [deleteShifts, { isLoading: isDeleteShiftsLoading }] =
    useDeleteShiftsDateMutation();

  // This can be removed
  const { refetch: refetchCalendar, data: calendarData } = useGetCalendarQuery(
    {
      locationId: activeLocationId,
      month: date.getMonth() + 1,
      year: date.getFullYear(),
      seniority: activeSeniorityId,
    },
    { skip: !activeLocationId }
  );

  const [isOpen, setOpen] = useState(false);

  if (skeleton) {
    return (
      <div
        className={`rounded-b-2xl flex flex-col gap-4 rounded-tr-2xl px-4 py-4 shadow-lg ${className}`}
      >
        <div className="flex justify-between items-center">
          <div className="font-semibold text-base text-gray-300 animate-pulse text-transparent bg-gray-300 rounded">
            <pre>&nbsp;&nbsp;&nbsp;&nbsp;</pre>
          </div>
          <button className="z-10" disabled={true}>
            <HiDotsHorizontal
              className="text-transparent animate-pulse w-8 h-4 bg-gray-300 rounded"
              size={25}
            />
          </button>
        </div>
        <div className="flex justify-between items-center text-secondary font-semibold text-2xl">
          <div className="animate-pulse text-transparent bg-gray-300 rounded">
            <pre>&nbsp;&nbsp;&nbsp;&nbsp;</pre>
          </div>

          <button className="animate-pulse text-transparent bg-gray-300 rounded-full">
            <HiCheckCircle className="text-transparent" size={28} />
          </button>
        </div>
      </div>
    );
  }

  const closedDates = calendarData?.calendar
    ?.filter((c: any) => c.isClosed)
    .map((c: any) => new Date(c.date));

  const disabledDates = closedDates
    ? isDisabled
      ? [
          date,
          ...closedDates.map((cd: string) =>
            DateTime.fromISO(cd).setZone(TIME_ZONE).toJSDate()
          ),
        ]
      : [
          ...closedDates.map((cd: string) =>
            DateTime.fromISO(cd).setZone(TIME_ZONE).toJSDate()
          ),
        ]
    : [];

  const successFunction = () => {
    dispatch(shiftsApi.util.resetApiState());
    refetchCalendar();
  };

  return (
    <div
      onClick={onClick}
      className={`rounded-b-2xl cursor-pointer flex flex-col rounded-tr-2xl px-4 py-4 ${className} ${
        bgClasses[isOpenRequest ? "openToRequest" : "default"][
          isActive ? "active" : "inActive"
        ]
      } ${isDisabled ? "!bg-[#D1DBAF99] shadow-none" : ""}`}
    >
      <div className="flex justify-between items-center">
        <div
          className={`flex gap-x-2 items-center text-sm ${
            isActive ? "text-white" : "text-secondary"
          } font-medium`}
        >
          {day.toUpperCase()}
          {isFinalized ? <IoMdLock className="h-4 w-4" /> : null}
        </div>

        {showContextMenu && (
          <ContextMenu
            disabled={isDisabled}
            loc={menuLoc}
            isOpen={isOpen}
            setOpen={setOpen}
            menu={
              <div className="bg-white rounded-lg flex flex-col divide-y-2">
                <button
                  className="hover:bg-slate-300 text-start p-3"
                  onClick={() => {
                    navigate(url);
                  }}
                >
                  <Typography
                    tag="span"
                    className="whitespace-nowrap p-3 font-semibold"
                  >
                    Estimate Manpower Required
                  </Typography>
                </button>
                {summary ? (
                  <>
                    <MenuItemWCalendar
                      name={"Copy My Settings"}
                      yellowDates={[date]}
                      disabledDates={disabledDates}
                      date={date}
                      onSubmit={async (dates) => {
                        const response = await copySettings({
                          locationId: activeLocationId,
                          seniority: activeSeniorityId,
                          from: formatDateAPI(date),
                          to: dates.map((d) => formatDateAPI(d)),
                        });
                        handleResponse(
                          response,
                          "Settings copied successfully",
                          successFunction
                        );
                        closeModal();
                      }}
                    />
                    <MenuItemWithConfirm
                      name={"Wipe Shift Data"}
                      isLoading={isDeleteSchedulesLoading}
                      onSubmit={async () => {
                        const response = await deleteSchedules({
                          locationId: activeLocationId,
                          seniority: activeSeniorityId,
                          date: formatDateAPI(date),
                        });
                        handleResponse(
                          response,
                          "Shift data wiped from date.",
                          successFunction
                        );
                        setOpen(false);
                      }}
                    />
                    <MenuItemWithConfirm
                      name={"Wipe Shift Data + Settings"}
                      isLoading={isDeleteShiftsLoading}
                      onSubmit={async () => {
                        const response = await deleteShifts({
                          locationId: activeLocationId,
                          seniority: activeSeniorityId,
                          date: formatDateAPI(date),
                        });
                        handleResponse(
                          response,
                          "Shift and settings data wiped from date",
                          successFunction
                        );
                        setOpen(false);
                      }}
                    />
                  </>
                ) : null}
              </div>
            }
          >
            <button>
              <HiDotsHorizontal
                className={`${isActive ? "text-white" : " text-black3"}`}
                size={25}
              />
            </button>
          </ContextMenu>
        )}
      </div>
      <div
        className={`flex justify-between items-center ${
          isActive ? "text-white" : "text-secondary"
        } font-medium text-[36px]`}
      >
        <div>{monthName}</div>
        {isEstimated ? (
          <button
            onClick={(e) => {
              if (isDisabled) {
                return;
              }
              e.stopPropagation();
              navigate(url);
            }}
          >
            <HiCheckCircle
              className={`${
                (summary as any)?.estimatedPercentage < 100
                  ? isActive
                    ? "text-white"
                    : "text-black3"
                  : isActive
                  ? "text-green-200"
                  : "text-green-600"
              }`}
              size={28}
            />
          </button>
        ) : null}
      </div>
      {others ? others : null}
    </div>
  );
};

export default CalenderCard;
