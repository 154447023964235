import { InfinitySpin } from "react-loader-spinner";
import { Shift, ShiftType } from "../../interface/shift";
import useGroupShifts from "./hooks/useGroupShifts";
import ShiftBlock, { GROUP_COLORS } from "./ShiftBlock";
import UserArrow from "../Icons/UserArrow";
import RoundedTickLarge from "../Icons/RoundedTickLarge";
import UserCancel from "../Icons/UserCancel";
import { Doctor } from "../../interface/doctor";

type DayViewProps = {
  shiftsByTiming: {
    time: { from: string; to: string };
    type: ShiftType;
    shifts: Shift[];
  }[];
  dayOff: { doctors: Doctor[] };
  isFetching: boolean;
  isLoading: boolean;
  day: string;
  date: string;
  dateStatus: "requests-not-released" | "requests-released" | "finalised";
  columnType: "double" | "single";
  setColumnType: React.Dispatch<React.SetStateAction<"double" | "single">>;
  setDashboardParams: (input: {
    date?: Date;
    cType?: "double" | "single";
  }) => void;
};

export type TimeBlock = "morning" | "noon" | "night";

export type Key = TimeBlock | "standBy" | "dayOff";

export type ShiftGroup = {
  timeGroups?: {
    time: { from: string; to: string };
    shifts: Shift[];
    type: ShiftType;
  }[];
  doctors?: any[];
  label: string;
  key: Key;
};

export type GroupedDoctors = ShiftGroup[];

const getSuperScript = (number: string) => {
  if (
    ["1", "2", "3"].includes(number[number.length - 1]) &&
    ((number.length > 1 &&
      !["11", "12", "13"].includes(number.slice(number.length - 2))) ||
      number.length === 1)
  ) {
    switch (number[number.length - 1]) {
      case "1":
        return "st";
      case "2":
        return "nd";
      case "3":
        return "rd";
    }
  }
  return "th";
};

const DayView = ({
  shiftsByTiming,
  dayOff,
  isFetching,
  isLoading,
  day,
  date,
  dateStatus,
  columnType,
  setColumnType,
  setDashboardParams,
}: DayViewProps) => {
  const { groupedShifts, timeBlockSummary } = useGroupShifts({
    shiftsByTiming,
    dayOff,
  });

  return (
    <div
      className={`h-full flex flex-col pt-6 relative ${
        isFetching ? "animate-pulseFast" : ""
      } transition-[width] duration-700`}
      style={{ width: columnType === "double" ? "26%" : "18%" }}
    >
      <div className="w-full h-[70px] flex mb-3 peer transition-all">
        <div className="bg-secondary rounded-l-xl h-full w-[80%] flex justify-between items-center py-3 px-6">
          <div className="relative py-2">
            <div
              className={`text-white ${
                columnType === "double" ? "text-5xl" : "text-4xl"
              } font-medium text-center`}
            >
              {date}
            </div>
            <div className="absolute text-white font-medium text-sm top-0.5 -right-3.5">
              {getSuperScript(date)}
            </div>
            {columnType === "single" && (
              <div className="text-gray2 text-[18px] font-medium text-center">
                {day.toUpperCase()}
              </div>
            )}
          </div>
          {columnType === "double" && (
            <div className="text-gray2 text-[28px] font-medium text-center">
              {day.toUpperCase()}
            </div>
          )}
          <div
            className="flex gap-x-1 items-center cursor-pointer"
            onClick={() => {
              const newColumnType =
                columnType === "single" ? "double" : "single";
              setColumnType(newColumnType);
              setDashboardParams({
                cType: newColumnType,
              });
            }}
          >
            <div className="h-[38px] w-[13px] bg-white rounded"></div>
            <div
              className={`h-[28px] w-[13px] ${
                columnType === "double"
                  ? "bg-white"
                  : "border-2 border-white bg-secondary"
              } rounded transition-all`}
            ></div>
          </div>
          {dateStatus === "requests-not-released" ? (
            <div className="scale-[1.33]">
              <UserCancel />
            </div>
          ) : dateStatus === "requests-released" ? (
            <div>
              <UserArrow />
            </div>
          ) : (
            <div>
              <RoundedTickLarge />
            </div>
          )}
        </div>
        <div
          className="w-[20%] bg-white h-full border-2 border-secondary rounded-r-xl p-2 flex flex-col gap-2 justify-center"
          id={"uid"}
        >
          {(["morning", "noon", "night"] as Key[]).map((key) => {
            return isLoading ? (
              <div className="w-full h-full bg-gray2 rounded animate-pulseFast"></div>
            ) : (
              <div
                className="w-full h-full bg-gray2 rounded relative"
                key={key}
              >
                <div
                  className={`h-full ${GROUP_COLORS[key].bgColor} rounded absolute top-0 left-0`}
                  style={{
                    width: `${timeBlockSummary[key]?.percentage ?? "0"}%`,
                  }}
                ></div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-full h-[70px] justify-between mb-3 hidden peer-has-[#uid:hover]:flex hover:flex transition-all absolute">
        <div
          className={`${GROUP_COLORS["morning"].bgColor2} rounded-l-xl ${GROUP_COLORS["morning"].borderColor} border-2 border-l-0 border-r-0 flex items-center`}
        >
          <div className="bg-secondary border-2 border-secondary rounded-xl h-[104%] flex justify-between items-center py-3 px-3 relative z-10">
            <div className="text-white text-5xl font-medium text-center relative">
              {date}
              <div className="absolute text-sm -top-2 -right-2">
                {getSuperScript(date)}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white h-full rounded-r-xl flex flex-grow">
          {(["morning", "noon", "night"] as Key[]).map((key, index) => {
            return isLoading ? (
              <div
                className={`p-2 flex-grow ${
                  GROUP_COLORS[key].borderColor
                } border-2 border-l-0 ${GROUP_COLORS[key].bgColor2} ${
                  index === 2 ? "rounded-r-xl animate-pulse" : ""
                }`}
              ></div>
            ) : (
              <div
                className={`p-2 flex-grow ${
                  GROUP_COLORS[key].borderColor
                } border-2 border-l-0 ${GROUP_COLORS[key].bgColor2} ${
                  index === 2 ? "rounded-r-xl" : ""
                }`}
              >
                <div
                  className="w-full h-[12px] bg-white relative rounded"
                  key={key}
                >
                  <div
                    className={`h-full ${GROUP_COLORS[key].bgColor} rounded absolute top-0 left-0`}
                    style={{
                      width: `${timeBlockSummary[key]?.percentage ?? "0"}%`,
                    }}
                  ></div>
                </div>
                <div className="capitalize text-center font-bold text-xs mt-1">
                  {key}
                </div>
                <div
                  className={`capitalize text-center font-bold text-xs mt-1 ${GROUP_COLORS[key].textColor}`}
                >
                  {timeBlockSummary[key]?.doctorsFilled ?? 0}/
                  {timeBlockSummary[key]?.totalSlots ?? 0}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {!isLoading ? (
        <>
          {groupedShifts.length > 0 ? (
            <div className="flex-grow overflow-y-scroll small-scrollbar">
              {groupedShifts.map((shiftGroup) => (
                <ShiftBlock shiftGroup={shiftGroup} columnType={columnType} />
              ))}
            </div>
          ) : (
            <div className="flex-grow flex items-center justify-center">
              <div className="font-semibold text-2xl w-[30%] text-center">
                No shifts or day offs on this day
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="flex-grow flex items-center justify-center">
          <div>
            <InfinitySpin width="200px" color="#67823A" />
          </div>
        </div>
      )}
    </div>
  );
};

export default DayView;
