import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
} from "@dnd-kit/core";

import {
  Dispatch,
  SetStateAction,
  createContext,
  useState,
  FC,
  PropsWithChildren,
} from "react";

import DoctorCard from "../components/Cards/DoctorCard";
import { useCreateScheduleMutation } from "../store/rosterApi";
import useHandleOnScheduleAdd from "../hooks/estimation/handleOnScheduleAdd";
import handleResponse from "../utils/handleResponse";
import { getDateRange } from "../utils/date";
import { useGetShifts } from "../hooks/estimation/useGetShifts";
import { Doctor } from "../interface/doctor";

export const EstDndContext = createContext<{
  activeDoctor: Doctor | undefined;
  setActiveDoctor: Dispatch<SetStateAction<string | undefined>>;
  activeShiftId: string | undefined;
  setActiveFilterDoctors: Dispatch<SetStateAction<Record<string, Doctor[]>>>;
  activeFilterDoctors: Record<string, Doctor[]>;
}>({
  activeDoctor: undefined,
  setActiveDoctor: () => undefined,
  activeShiftId: undefined,
  setActiveFilterDoctors: () => undefined,
  activeFilterDoctors: {},
});

const EstimationDndContext: FC<
  PropsWithChildren<{ activeDate: Date; prevDate: Date; nextDate: Date }>
> = ({ children, activeDate, prevDate, nextDate }) => {
  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: { delay: 200, tolerance: 10 },
  });
  const [activeDoctor, setActiveDoctor] = useState<undefined | any>(undefined);
  const [activeShiftId, setActiveShiftId] = useState<undefined | string>(
    undefined
  );
  const [activeFilterDoctors, setActiveFilterDoctors] = useState<
    Record<string, Doctor[]>
  >({});

  const { data: shiftsData } = useGetShifts({
    activeDate,
    prevDate,
    nextDate,
  });

  const index: number =
    activeShiftId && shiftsData
      ? shiftsData.findIndex((shifts) =>
          shifts.find((shift) => shift._id === activeShiftId)
        )
      : 1;

  const handleOnScheduleAdd = useHandleOnScheduleAdd({
    ...getDateRange(
      { "0": prevDate, "1": activeDate, "2": nextDate }[
        index.toString() as "0" | "1" | "2"
      ]
    ),
  });

  const [createSchedule, { isLoading }] = useCreateScheduleMutation({
    fixedCacheKey: "drag-and-drop",
  });

  return (
    <EstDndContext.Provider
      value={{
        activeDoctor,
        setActiveDoctor,
        activeShiftId,
        activeFilterDoctors,
        setActiveFilterDoctors,
      }}
    >
      <DndContext
        sensors={[pointerSensor]}
        onDragStart={(event) => {
          if (event.active.data.current) {
            setActiveDoctor(event.active.data.current.doctor);
          }
        }}
        onDragMove={(event) => {
          if (event.over) {
            setActiveShiftId(event.over.id as string);
          } else if (activeShiftId) {
            setActiveShiftId(undefined);
          }
        }}
        onDragEnd={async (event) => {
          if (event.over) {
            if (event.over.data.current!.type === "shiftCard") {
              const response = await createSchedule({
                shiftId: event.over?.id,
                doctorId: event.active.id,
              });
              handleResponse(response, "Schedule added", () => {
                handleOnScheduleAdd();
              });
            } else {
              if (
                !activeFilterDoctors[event.over.data.current!.key].find(
                  (doctor) => doctor._id === activeDoctor._id
                )
              ) {
                setActiveFilterDoctors({
                  ...activeFilterDoctors,
                  [event.over.data.current!.key]: [
                    ...activeFilterDoctors[event.over.data.current!.key],
                    event.active.data.current!.doctor,
                  ],
                });
              }
            }
          }

          setActiveShiftId(undefined);
          setActiveDoctor(undefined);
        }}
      >
        {children}
        <DragOverlay dropAnimation={null}>
          {activeDoctor && (
            <DoctorCard
              name={activeDoctor.user.name}
              doctor={activeDoctor}
              doctorId={activeDoctor._id}
              isActive={false}
              isFetching={isLoading}
              onClickHandler={function (): void {
                throw new Error("Function not implemented.");
              }}
              seniority={activeDoctor.seniority.toString()}
              type={"doctor-filter-no-shift"}
            />
          )}
        </DragOverlay>
      </DndContext>
    </EstDndContext.Provider>
  );
};

export default EstimationDndContext;
